import * as coverApi from "../../api/CoverLetterApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";


function resetState(state) {
  state.coverLetterList = [];
  state.hasError = false;
}

const coverLetterModule = {
  namespaced: true,
  state: {
    coverLetterList: [],
    hasError: false
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Cover Letter Module", "coverLetter_store.loadData");
      state.coverLetterList = payload.coverLetterList ?? [];
    },
    removeResume(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    },
    setCoverLetterList(state, data) {
        state.coverLetterList = data;
    },
    hasErrorCoverLetter(state, status, method) {
      if (status) {
        log.logTrace("Setting error in activeCoverLetter_store.errorLoadCurrentCoverLetter", method);
        resetState(state);
        state.hasError = true;
      } else {
        state.hasError = false;
      }
    }
  },
  actions: {
    async loadCoverLetterList(context) {
      let result = await coverApi.GetCoverLettersList();
      if (!result.success) {
        log.logWarning(
          "Failed to load User Cover Letter list data",
          "CoverLetter_store.loadCoverLetterList"
        );
        context.commit("hasErrorCoverLetter", true, "loadCoverLetterList");
      } else {
        log.logTrace(`Retrieved cover letter list for user.`, "coverLetter.loadCoverLetterList");
        context.commit("setCoverLetterList", result.data);
        context.commit("hasErrorCoverLetter", false, "loadCoverLetterList");
      }
    }
  }
};

export { coverLetterModule };