import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.otherSkills = [];
  state.points = [];
  state.otherNotes = "";
  state.professionalSummary = "";
}

const finalInfoModule = {
  namespaced: true,
  state: {
    otherSkills: [],
    points: [],
    otherNotes: "",
    professionalSummary: ""
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Final Info Module", "finalInfo_store.loadData");
      state.otherSkills = payload.otherSkills;
      state.points = payload.points;
      state.otherNotes = payload.otherNotes;
      state.professionalSummary = payload.professionalSummary;
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveFinalInfo(context) {
      let result = await resApi.PostFinalInfo({
        resumeId: context.rootState.resumeId,
        otherSkills: context.state.otherSkills,
        points: context.state.points,
        otherNotes: context.state.otherNotes,
        professionalSummary: context.state.professionalSummary
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save Final Info data",
          "finalInfo_store.saveFinalInfo"
        );
      }
    }
  }
};

export { finalInfoModule };
