<template>
  <v-expansion-panel-content
    eager
    class="r-col rounded-0 panel-bar-parent mb-n4"
  >
    <v-system-bar height="5" color="accent" class="mx-n10"></v-system-bar>
    <slot></slot>
  </v-expansion-panel-content>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ResumationExpansionPanelContent",
  data: () => ({}),
  props: {},
  components: {},
  methods: {},
  mounted() {},
  computed: {}
};
</script>

<style>
.panel-bar-parent {
  overflow: hidden;
}
</style>
