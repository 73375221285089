<template>
  <status-bar v-model="anyValues">
    <h3 v-if="titleSet" class="pb-3 pt-2">{{ titleText }}</h3>
    <v-combobox
      :value="value"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="label"
      :tabindex="tabindex"
      :autofocus="autofocus"
      :readonly="readonly"
      :disabled="disabled"
      @change="updateValue"
    ></v-combobox>
  </status-bar>
</template>

<script>
import StatusBar from "./StatusBar.vue";
import * as disp from "@/utility/display.js";

// @ is an alias to /src
export default {
  name: "ResumationTextField",
  components: {
    "status-bar": StatusBar
  },
  data: () => ({}),
  props: {
    value: {
      type: [String, Object]
    },
    items: {
      type: Array
    },
    label: {
      type: String,
      default: ""
    },
    titleText: {
      type: String,
      default: ""
    },
    tabindex: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: "name"
    },
    itemValue: {
      type: String,
      default: "id"
    },
  },
  methods: {
    updateValue: function(value) {
      this.$emit("input", value);
    }
  },
  mounted() {},
  computed: {
    anyValues() {
      if (typeof this.value === "string") {
        if (!disp.IsNullorWhitespace(this.value)) {
          return "V";
        }
      } else if (this.value[this.itemValue] !== "") {
        return "O";
      }

      return "";
    },
    titleSet() {
      return !disp.IsNullorWhitespace(this.titleText);
    }
  }
};
</script>
