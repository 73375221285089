import axios from "axios";
import { Get, Post, Delete } from "./ApiBase";
import * as log from "../utility/logging/logger";

export async function GetUserReviews() {
  return await Get("/api/GetUserReviewsUser");
}

export async function GetReview(reviewId) {
  return await Get(`/api/GetReviewUser?reviewId=${reviewId}`);
}

export async function RemoveResume(reviewId) {
  return await Delete(`/api/RemoveSavedResume?savedResumeId=${reviewId}`);
}

export async function CreateReview(name, note, fileBase64, contentType, fileName) {
  return await Post('/api/CreateReview', { name, note, fileBase64, contentType, fileName });
}

// Special function to handle retrieving a file's contents based on URL.
export async function GetFile(url) {
  await axios
    .get(url)
    .then(result => {
      return { success: true, data: result };
    })
    .catch(error => {
      log.logWarning(`Error: ${error?.toJSON()?.message}`, "CareerApiUser.GetFile");
      return { success: false, error };
    });
}