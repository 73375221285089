import * as careerApi from "../../api/CareerApiUser";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
    state.id = "";
    state.envId = "";
    state.userDisplay = "";
    state.userId = "";
    state.name = "";
    state.note = "";
    state.dateSubmitted = "";
    state.dateUpdated = "";
    state.dateReviewed = "";
    state.status = "";
    state.reviewerDisplay = "";
    state.reviewerUserId = "";
    state.fileRef = "";
    state.imageRef = "";
    state.previewRef = "";
    state.comments = [];
    state.fileName = "";
    state.loadResumeError = "";
    state.deleteResumeError = "";
}

const currentReviewModule = {
    namespaced: true,
    state: {
        id: "",
        envId: "",
        userDisplay: "",
        userId: "",
        name: "",
        note: "",
        dateSubmitted: "",
        dateUpdated: "",
        dateReviewed: "",
        status: "",
        reviewerDisplay: "",
        reviewerUserId: "",
        fileRef: "",
        imageRef: "",
        previewRef: "",
        comments: [],
        fileName: "",
        loadResumeError: "",
        deleteResumeError: "",
    },
    getters: {
        getField
    },
    mutations: {
        updateField,
        loadCurrentReview(state, payload) {
            log.logTrace("Loading Current Review Module current review", "currentReview_store.loadCurrentReview");
            state.id = payload.data.id;
            state.envId = payload.data.envId;
            state.userDisplay = payload.data.userDisplay;
            state.userId = payload.data.userId;
            state.name = payload.data.name;
            state.note = payload.data.note;
            state.dateSubmitted = payload.data.dateSubmitted;
            state.dateUpdated = payload.data.dateUpdated;
            state.dateReviewed = payload.data.dateReviewed;
            state.status = payload.data.status;
            state.reviewerDisplay = payload.data.reviewerDisplay;
            state.reviewerUserId = payload.data.reviewerUserId;
            state.fileRef = payload.data.fileRef;
            state.imageRef = payload.data.imageRef;
            state.previewRef = payload.data.previewRef;
            state.comments = payload.data.comments;
            state.fileName = payload.data.fileName;

            state.loadResumeError = "";
        },
        errorLoadCurrentReview(state, payload) {
            log.logTrace("Setting error during load of current review", "currentReview_store.errorLoadCurrentReview");
            resetState(state);
            state.loadResumeError = payload;
        },
        errorDeleteCurrentResume(state, payload) {
            log.logTrace("Setting error during delete of current resume", "currentReview_store.errorDeleteCurrentResume");
            state.deleteResumeError = payload;
        },
        logout(state) {
            resetState(state);
        }
    },
    actions: {
        async loadReviewUser(context, payload) {
            let result = await careerApi.GetReview(payload.reviewId);
            if (!result.success) {
                log.logError(
                    "Failed to load user's resume review",
                    "currentReview_store.loadReviewUser"
                );
                context.commit("errorLoadCurrentReview", "Unable to load resume for review.");
            } else {
                log.logTrace(`Loaded user resume review as user.`, "currentReview_store.loadReviewUser");
                context.commit("loadCurrentReview", result);
            }
        },
        async removeSavedResume(context, payload) { 
            let result = await careerApi.RemoveResume(payload.reviewId);
            if (!result.success) {
                log.logError(
                    "Failed to remove user's saved resume",
                    "currentReview_store.removeSavedResume"
                );
                context.commit("errorDeleteCurrentResume", "Unable to remove resume for review.");
            } else {
                log.logTrace(`Removed user resume review.`, "currentReview_store.removeSavedResume");
            }
        }
    }
};

export { currentReviewModule };
