import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: "#0059A3", // var(--v-primary-base)
        secondary: "#F1B500", // #FFCDD2
        accent: "#C6C6C6", // #3F51B5
        appBar: "#0059A3",
        navDrawer: "#eeeeee",
        error: "#bf0025",
        barGray: "#bbbbbb",
        footerText: "#535353",
        navSelected: "#c6c6c6",
        resumeBackground: "#c6c6c6",
        resumePlaceholder: "#ffffff",
        resumeBacker: "#c6c6c6",
        resumeErrorBox: "#dddddd",
        resumeErrorText: "#333333",
        updatedText: "#707070",
      }
    }
  }
});
