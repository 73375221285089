import axios from "axios";
import * as env from "../utility/env";
import * as log from "../utility/logging/logger";

export async function Get(apiEndpoint) {
  let apiUrl = env.GetOrigin() + apiEndpoint;
  log.logTrace(`Get: ${apiUrl}`, "ApiBase.Get");
  var result = await axios
    .get(apiUrl)
    .then(result => {
      return result.data;
    })
    .catch(err => {
      log.logWarning(`Error: ${err?.toJSON()?.message}`, "ApiBase.Get");
      return { success: false, msg: "Unexpected Error", ...err };
    });

  return result;
}

export async function Post(apiEndpoint, postBody) {
  let apiUrl = env.GetOrigin() + apiEndpoint;
  log.logTrace(`Post: ${apiUrl}`, "ApiBase.Post");
  var result = await axios
    .post(apiUrl, postBody)
    .then(result => {
      return result.data;
    })
    .catch(err => {
      log.logWarning(`Error: ${err?.toJSON()?.message}`, "ApiBase.Post");
      return { success: false, msg: "Unexpected Error", ...err };
    });

  return result;
}

export async function Delete(apiEndpoint, postBody) {
  let apiUrl = env.GetOrigin() + apiEndpoint;
  log.logTrace(`Delete: ${apiUrl}`, "ApiBase.Delete");
  var result = await axios
    .delete(apiUrl, postBody)
    .then(result => {
      return result.data;
    })
    .catch(err => {
      log.logWarning(`Error: ${err?.toJSON()?.message}`, "ApiBase.Delete");
      return { success: false, msg: "Unexpected Error", ...err };
    });

  return result;
}