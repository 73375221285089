<template>
  <status-bar v-model="anyValues">
    <h3 class="pb-3 pt-2">{{ label }}</h3>
    <transition-group name="list-94" tag="span">
      <v-row
        v-for="(item, index) in internalValues"
        :key="item[keyName]"
        class="list-item"
      >
        <v-col>
          <v-combobox
            v-model="item[dataNameOne]"
            :label="labelOne"
            :items="validOptions(item[dataNameOne])"
            :tabindex="listti(index, 0)"
            :autofocus="autofocus"
            @change="updateValue()"
            @input.native="saveNativeOne($event)"
          ></v-combobox>
        </v-col>
        <v-col>
          <v-combobox
            v-model="item[dataNameTwo]"
            :label="labelTwo"
            :items="listTwoOptions"
            :tabindex="listti(index, 1)"
            @change="updateValue()"
            @input.native="saveNativeTwo($event)"
          >
            <template v-slot:append-outer>
              <v-btn
                icon
                @click="removeItem(item[keyName])"
                :tabindex="listti(index, 2)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-combobox>
        </v-col>
      </v-row>
    </transition-group>
    <v-row v-if="limitMaxSelections()">
      <v-col>
        <v-btn outlined @click="appendEmptyRow()" :tabindex="addButtonTi">
          <v-icon>mdi-plus</v-icon>
          {{ buttonLabel }}
        </v-btn>
      </v-col>
    </v-row>
  </status-bar>
</template>

<script>
import * as uuid from "../utility/guid";
import * as Display from "../utility/display";
import StatusBar from "./StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ResumationDualList",
  components: {
    "status-bar": StatusBar
  },
  data: () => ({
    internalValues: [],
    nativeOne: "",
    nativeTwo: ""
  }),
  props: {
    value: {
      type: Array
    },
    listOneOptions: {
      type: Array,
      default: () => []
    },
    listTwoOptions: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    labelOne: {
      type: String,
      default: ""
    },
    labelTwo: {
      type: String,
      default: ""
    },
    tabindex: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    buttonLabel: {
      type: String,
      default: "Add"
    },
    dataNameOne: {
      type: String,
      default: "one"
    },
    dataNameTwo: {
      type: String,
      default: "two"
    },
    keyName: {
      type: String,
      default: "id"
    },
    maxSelection: {
      type: Number,
      default: null
    }
  },
  mounted() {
    this.internalValues = this.value;
  },
  beforeDestroy() {
    // Vue 3 updates to beforeUnmount()
    // save the native values if the user didn't trigger the change event on the combobox
    if (this.nativeOne.length > 0 || this.nativeTwo.length > 0) {
      let curIdx = this.internalValues.length - 1;
      if (this.nativeOne.length > 0) {
        this.internalValues[curIdx][this.dataNameOne] = this.nativeOne;
      }

      if (this.nativeTwo.length > 0) {
        this.internalValues[curIdx][this.dataNameTwo] = this.nativeTwo;
      }
      this.updateValue();
    }
  },
  methods: {
    updateValue: function() {
      this.$emit("input", this.internalValues);
      this.nativeOne = "";
      this.nativeTwo = "";
    },
    listti(listIndex, subListIndex) {
      let ti = this.tabindex - 1 + 3 * listIndex + subListIndex; // 3 tabs in each list, 1 tab before list
      return ti.toString();
    },
    validOptions(current) {
      if (this.listOneOptions.length == 0) {
        //short circuit here
        return this.listOneOptions;
      }

      let setCurrent = new Set();
      this.internalValues.forEach(l => {
        setCurrent.add(l[this.dataNameOne]);
      });
      return [current, ...this.listOneOptions.filter(l => !setCurrent.has(l))];
    },
    removeItem(id) {
      this.internalValues = this.internalValues.filter(
        l => l[this.keyName] != id
      );
      this.updateValue();
    },
    appendEmptyRow() {
      let newItem = {};
      newItem[this.keyName] = uuid.gen();
      newItem[this.dataNameOne] = "";
      newItem[this.dataNameTwo] = "";

      this.internalValues.push(newItem);
      this.updateValue();
    },
    saveNativeOne(evt) {
      this.nativeOne = evt.srcElement.value;
    },
    saveNativeTwo(evt) {
      this.nativeTwo = evt.srcElement.value;
    },
    limitMaxSelections(){
      return this.maxSelection == null || this.internalValues.length <= this.maxSelection - 1; 
   }
  },
  computed: {
    addButtonTi() {
      let ti = this.internalValues.length * 3 + (this.tabindex - 1); // tab index for the Add button
      return ti.toString();
    },
    anyValues() {
      if (
        this.internalValues.find(
          l => !Display.IsNullorWhitespace(l[this.dataNameOne])
        ) !== undefined
      ) {
        return "V";
      }
      return "";
    }
  }
};
</script>
