import * as authApi from "../../api/AuthApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.userInfo.username = "";
  state.userInfo.id = "";
  state.auth.authType = "";
  state.emailConfirmed = false;
  state.isAuthed = false;
  state.refreshTimeout = -1;
  state.isPremium = false;
  state.subscriptionStatus = "";
  state.currentPeriodStart = "";
  state.currentPeriodEnd = "";
  state.remainingAiOptimizerAttempts = 0;
  state.jobFitAttempts = 0;
  state.maxJobFitAttempts = 0;
  state.careerPathwayAttempts = 0;
  state.maxCareerPathwayAttempts = 0;
  state.coverLetterAttempts = 0;
  state.maxCoverLetterAttemps = 0;
}

async function HandleRefresh(context) {
  var response = await authApi.RefreshToken();
  if (!response) {
    context.commit("logout");
  } else {
    let newRefresh = setTimeout(() => HandleRefresh(context), 3600000); //60 * 60 * 1000
    context.commit("setRefreshTimeout", newRefresh);
  }
}

async function LogUserIn(context) {
  let authResponse = await authApi.GetToken();

  let refreshTimeout = -1;

  // check if the call was successful.
  if (authResponse.success) {
    if (!authResponse.data) {
      context.commit("logout");
      log.logError(
        "Did not receive any data in auth response",
        "auth_store.logUserIn"
      );
      return false;
    }
    let data = authResponse.data;

    refreshTimeout = setTimeout(() => HandleRefresh(context), 3600000); //60 * 60 * 1000
    context.commit("login", {
      username: data.username,
      id: data.id,
      authType: data.providerName,
      refreshTimeout: refreshTimeout,
      emailConfirmed: data.emailConfirmed,
      isPremium: data.isPremium,
      subscriptionStatus: data.subscriptionStatus,
      currentPeriodStart: data.currentPeriodStart,
      currentPeriodEnd: data.currentPeriodEnd,
    });

    return true;
  } else {
    context.commit("logout");
    return false;
  }
}

const authModule = {
  namespaced: true,
  state: {
    userInfo: {
      username: "",
      id: ""
    },
    auth: {
      authType: ""
    },
    emailConfirmed: false,
    isAuthed: false,
    refreshTimeout: -1,
    isPremium: false,
    subscriptionStatus: "",
    currentPeriodStart: "",
    currentPeriodEnd: "",
    remainingAiOptimizerAttempts: 0,
    careerPathwayAttempts: 0,
    maxCareerPathwayAttempts: 0,
    jobFitAttempts: 0,
    maxJobFitAttempts: 0,
    coverLetterAttempts: 0,
    maxCoverLetterAttemps: 0
  },
  mutations: {
    login(state, payload) {
      state.isAuthed = true;
      state.userInfo.username = payload.username;
      state.userInfo.id = payload.id;
      state.auth.authType = payload.authType;
      state.refreshTimeout = payload.refreshTimeout;
      state.emailConfirmed = payload.emailConfirmed;
      state.isPremium = payload.isPremium;
      state.subscriptionStatus = payload.subscriptionStatus;
      state.currentPeriodStart = payload.currentPeriodStart;
      state.currentPeriodEnd = payload.currentPeriodEnd;
      state.remainingAiOptimizerAttempts = payload.remainingAiOptimizerAttempts;
      state.careerPathwayAttempts = payload.careerPathwayAttempts;
      state.maxCareerPathwayAttempts = payload.maxCareerPathwayAttempts;
      state.jobFitAttempts = payload.jobFitAttempts;
      state.maxJobFitAttempts = payload.maxJobFitAttempts;
      state.coverLetterAttempts = payload.coverLetterAttempts;
      state.maxCoverLetterAttemps = payload.maxCoverLetterAttemps
    },
    updateField,
    // no removeResume
    logout(state) {
      clearTimeout(state.refreshTimeout);
      resetState(state);
    },
    setRefreshTimeout(state, payload) {
      state.refreshTimeout = payload;
    },
    loadPremium(state, payload) {
      state.isPremium = payload.isPremium;
      state.subscriptionStatus = payload.subscriptionStatus;
      state.currentPeriodStart = payload.currentPeriodStart;
      state.currentPeriodEnd = payload.currentPeriodEnd;
      state.remainingAiOptimizerAttempts = payload.remainingAiOptimizerAttempts;
      state.careerPathwayAttempts = payload.careerPathwayAttempts;
      state.maxCareerPathwayAttempts = payload.maxCareerPathwayAttempts;
      state.jobFitAttempts = payload.jobFitAttempts;
      state.maxJobFitAttempts = payload.maxJobFitAttempts;
      state.coverLetterAttempts = payload.coverLetterAttempts;
      state.maxCoverLetterAttempts = payload.maxCoverLetterAttempts;
    }
  },
  getters: {
    getField
  },
  actions: {
    async logUserIn(context) {
      return await LogUserIn(context);
    },
    async refreshPremium(context) {
      try {
        var result = await authApi.GetToken();
        if (!result.success) {
          log.logError("Failed to refresh premium status", "auth_store.refreshPremium");
        }
        else {
          log.logTrace("Refreshed premium status", "auth_store.refreshPremium");
          context.commit("loadPremium", result.data);
        }
      }
      catch (err) {
        log.logError(err, "auth_store.refreshPremium");
      }
    }
  }
};

export { authModule };
