<template>
  <div>
    <v-combobox
      v-model="selectedField"
      :items="optionsList"
      :filter="customFilter"
      :placeholder="label"
      hide-selected
      hide-no-data
      multiple
      v-on:input="updateSelected"
      :autofocus="autofocus"
      :tabindex="tabindex"
      @input.native="saveNative($event)"
    ></v-combobox>
    <div style="min-height: 48px">
      <v-chip
        class="ma-2"
        close
        label
        outlined
        v-for="sel in selected"
        :key="sel.id"
        v-on:click:close="removeSelected(sel)"
      >
        {{ safeLen(sel.text) }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import * as UUID from "../utility/guid";
import * as disp from "../utility/display";

// @ is an alias to /src
export default {
  name: "MultiSelect",
  data: () => ({
    internalSelected: [],
    selectedField: [],
    menuprops: { openOnClick: false },
    nativeInput: ""
  }),
  props: {
    optionsList: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    selected: {
      type: Array,
      default: () => []
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: ""
    },
    maxSelections: { 
      type: Number, 
      default: null 
    }
  },
  computed: {},
  mounted() {
    if (this.internalSelected.length == 0 && this.selected.length != 0) {
      this.internalSelected = this.selected;
    }
  },
  watch: {
    selected: function(newSelected) {
      if (this.internalSelected.length == 0 && newSelected.length != 0) {
        this.internalSelected = newSelected;
      }
    }
  },
  beforeDestroy() {
    // Vue 3 updates to beforeUnmount()
    // save the native value if the user didn't trigger the input event on the combobox
    if (this.nativeInput.length > 0) {
      this.selectedField = [this.nativeInput];
      this.updateSelected();
    }
  },
  methods: {
    saveNative(evt) {
      this.nativeInput = evt.srcElement.value;
    },
    safeLen(str) {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return disp.Ellipsis(str, 30);
        case "sm":
          return disp.Ellipsis(str, 60);
        case "md":
          return disp.Ellipsis(str, 60);
        case "lg":
          return disp.Ellipsis(str, 60);
        case "xl":
          return disp.Ellipsis(str, 70);
      }
    },
    updateSelected() {
      if (
        this.selectedField.length > 0 &&
        this.selectedField[0].length > 0 &&
        !this.internalSelected.some(item => item.text == this.selectedField[0]) &&
        (this.maxSelections == null || this.internalSelected.length <= this.maxSelections - 1 )
      ) {
        this.internalSelected.push({
          text: this.selectedField[0],
          id: UUID.gen()
        });
        // emit update
        this.$emit("update:selected", this.internalSelected);
        this.$emit("input", this.internalSelected);

        // clean native input tracker
        this.nativeInput = "";
      }
      this.selectedField = [];
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.toLowerCase();
      const textTwo = itemText.toLowerCase();
      const searchText = queryText.toLowerCase();

      if (queryText.length > 2) {
        return (
          textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        );
      }

      return false;
    },
    removeSelected: function(selectedValue) {
      if (
        selectedValue.text.length > 0 &&
        this.internalSelected.some(item => item.id == selectedValue.id)
      ) {
        this.internalSelected = this.internalSelected.filter(
          item => item.id != selectedValue.id
        );

        // emit update
        this.$emit("update:selected", this.internalSelected);
        this.$emit("input", this.internalSelected);
      }
    }
  }
};
</script>
