import { Get, Post } from "../api/ApiBase";

export async function GetResumesForUser() {
  return await Get("/api/GetListResumes");
}

export async function GetFullResume(resumeId) {
  return await Get("/api/GetResume?resumeId=" + resumeId);
}

export async function GetBaseLists() {
  return await Get("/api/GetBaseLists");
}

export async function PostResName(payload) {
  return await Post("/api/SetResName", payload);
}

export async function PostAddress(payload) {
  return await Post("/api/SetAddress", payload);
}

export async function PostAdjective(payload) {
  return await Post("/api/SetSelectedAdjectives", payload);
}

export async function PostRole(payload) {
  return await Post("/api/SetSelectedRoles", payload);
}

export async function PostCareerInterest(payload) {
  return await Post("/api/SetCareerInterest", payload);
}

export async function PostPersonalInterest(payload) {
  return await Post("/api/SetPersonalInterest", payload);
}

export async function PostHardSkills(payload) {
  return await Post("/api/SetHardSkill", payload);
}

export async function PostLicenseCerts(payload) {
  return await Post("/api/SetLicensesCert", payload);
}

export async function PostSoftSkills(payload) {
  return await Post("/api/SetSoftSkill", payload);
}

export async function PostLanguages(payload) {
  return await Post("/api/SetLanguage", payload);
}

export async function PostProfessionalOrganization(payload) {
  return await Post("/api/SetProfessionalOrg", payload);
}

export async function PostVolunteerOrganization(payload) {
  return await Post("/api/SetVolunteerOrg", payload);
}

export async function PostEducation(payload) {
  return await Post("/api/SetEducation", payload);
}

export async function PostWork(payload) {
  return await Post("/api/SetWork", payload);
}

export async function PostTargetIndustries(payload) {
  return await Post("/api/SetTargetIndustry", payload);
}

export async function PostTargetCompany(payload) {
  return await Post("/api/SetTargetCompany", payload);
}

export async function PostStrengths(payload) {
  return await Post("/api/SetStrengths", payload);
}

export async function PostFinalInfo(payload) {
  return await Post("/api/SetFinalInfo", payload);
}

export async function FetchAiOptimizedPhrasing(payload) {
  return await Post("/api/FetchAiOptimizedPhrasing", payload);
}