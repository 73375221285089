import * as resApi from "../../api/ResumeApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";

function resetState(state) {
  state.displayValue = "";
  state.addressLine1 = "";
  state.addressLine2 = "";
  state.city = "";
  state.state = "";
  state.country = "";
  state.zipCode = "";
  state.phoneNumber = "";
  state.email = "";
  state.linkedin = "";
  state.otherLink = "";
}

function calculateDisplayValue(state) {
  const addressValue =
    (state.addressLine1.length > 0 ? state.addressLine1 + " " : "") +
    (state.addressLine2.length > 0 ? state.addressLine2 + " " : "") +
    (state.city.length > 0 ? state.city + " " : "") +
    (state.state.length > 0 ? state.state : "") +
    (state.zipCode.length > 0 ? ", " + state.zipCode : "") +
    (state.country.length > 0 ? ", " + state.country : "");

  let displayValue = "";

  function appendField(field) {
    if (field.length > 0) {
      if (displayValue.length > 0) {
        displayValue += " | " + field;
      } else {
        displayValue += field;
      }
    }
  }

  appendField(state.phoneNumber);
  appendField(state.email);
  appendField(state.linkedin);
  appendField(state.otherLink);
  appendField(addressValue);

  state.displayValue = displayValue;
}

const addressModule = {
  namespaced: true,
  state: {
    displayValue: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    phoneNumber: "",
    email: "",
    linkedin: "",
    otherLink: "",
  },
  getters: {
    getField
  },
  mutations: {
    updateField(state, field) {
      updateField(state, field);
      calculateDisplayValue(state);
    },
    loadData(state, payload) {
      log.logTrace("Loading Address Module", "address_store.loadData");
      state.displayValue = payload.displayValue;
      state.addressLine1 = payload.addressLine1;
      state.addressLine2 = payload.addressLine2;
      state.city = payload.city;
      state.state = payload.state;
      state.country = payload.country;
      state.zipCode = payload.zipCode;
      state.phoneNumber = payload.phoneNumber;
      state.email = payload.email;
      state.linkedin = payload.linkedin;
      state.otherLink = payload.otherLink;
    },
    removeResume(state) {
      resetState(state);
    },
    logout(state) {
      resetState(state);
    }
  },
  actions: {
    async saveAddress(context) {
      let result = await resApi.PostAddress({
        resumeId: context.rootState.resumeId,
        addressLine1: context.state.addressLine1,
        addressLine2: context.state.addressLine2,
        displayValue: context.state.displayValue,
        city: context.state.city,
        state: context.state.state,
        country: context.state.country,
        zipCode: context.state.zipCode,
        phoneNumber: context.state.phoneNumber,
        email: context.state.email,
        linkedin: context.state.linkedin,
        otherLink: context.state.otherLink,
      });
      if (!result.success) {
        log.logWarning(
          "Failed to save address data",
          "address_store.saveAddress"
        );
      }
    }
  }
};

export { addressModule };
