<template>
  <v-dialog
    v-model="value"
    :persistent="persistent"
    @click:outside="closeDialog()"
    :max-width="$vuetify.breakpoint.xs ? 290 : maxWidth"
    v-bind="$attrs"
  >
    <v-card tile>
      <v-system-bar
        :height="$vuetify.breakpoint.xs ? 75 : 50"
        color="primary"
        class="white--text elevation-5"
        v-if="title"
      >
        <span class="text-h6 pl-2">{{ title }}</span>
      </v-system-bar>
      <v-system-bar height="5" color="accent" class=""></v-system-bar>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ResumationDialog",
  data: () => ({}),
  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String
    },
    persistent: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number,
      default: 600
    }
  },
  components: {},
  methods: {
    closeDialog: function() {
      if (!this.persistent) {
        this.$emit("input", false);
      }
    }
  },
  mounted() {},
  computed: {}
};
</script>
