import * as coverApi from "../../api/CoverLetterApi";
import * as log from "../../utility/logging/logger";
import { getField, updateField } from "vuex-map-fields";
import axios from 'axios';


function resetState(state) {
  state.coverLetterId = null;
  state.selectedResume = { id: null, name: "" };
  state.selectedResumeId = null;
  state.targetedIndustry = "";
  state.selectedSkills = [];
  state.industryInterest = "";
  state.companyName = "";
  state.companyDescription = "";
  state.positionName = "";
  state.roleInterest = "";
  state.workingGoals = "";
  state.coverLetterFeel = "";
  state.coverLetterChanges = "";
  state.reportBase64 = null;
  state.name = "";
  state.note = "";
  state.imageRef = null;
  state.fileRef = null;
  state.pdfRef = null;
  state.fileName = "";
  state.dateSaved = null;
  state.dateUpdated = null;
  state.loadCoverLetterError = "";
  state.isError = false;
  state.aiOptimizedPhrasing = "";
  state.isSaving = false;
  state.isPageAllowed = false;
}

const activeCoverLetterModule = {
  namespaced: true,
  state: {
    coverLetterId: null,
    selectedResume: { id: null, name: "" },    
    selectedResumeId: null,
    targetedIndustry: "",
    selectedSkills: [],
    industryInterest: "",
    companyName: "",
    companyDescription: "",
    positionName: "",
    roleInterest: "",
    workingGoals: "",
    coverLetterFeel: "",
    coverLetterChanges: "",
    reportBase64: null,
    name: "",
    note: "",
    imageRef: null,
    fileRef: null,
    pdfRef: null,
    fileName: "",
    dateSaved: null,
    dateUpdated: null,
    aiOptimizedPhrasing: "",
    loadCoverLetterError: "",
    isSaving: false,
    isPageAllowed: false,
  },
  getters: {
    getField
  },
  mutations: {
    updateField,
    loadData(state, payload) {
      log.logTrace("Loading Cover Letter Module", "activeCoverLetter_store.loadData");
      state.coverLetterId = payload.data.id ?? null;
      state.selectedResume.id = payload.data.FullCoverLetter.ResumeId ?? null;
      state.selectedResumeId = payload.data.FullCoverLetter.ResumeId ?? null;
      state.targetedIndustry = payload.data.FullCoverLetter.TargetedIndustry ?? "";
      state.selectedSkills = payload.data.FullCoverLetter.Skills ?? [];
      state.industryInterest = payload.data.FullCoverLetter.IndustryInterest ?? "";
      state.companyName = payload.data.FullCoverLetter.CompanyName ?? "";
      state.companyDescription = payload.data.FullCoverLetter.CompanyDescription ?? "";
      state.positionName = payload.data.FullCoverLetter.PositionName ?? "";
      state.roleInterest = payload.data.FullCoverLetter.RoleInterest ?? "";
      state.workingGoals = payload.data.FullCoverLetter.WorkingGoals ?? "";
      state.coverLetterFeel = payload.data.FullCoverLetter.Feel ?? "";
      state.aiOptimizedPhrasing = payload.data.FullCoverLetter.AiOptimizedPhrasing ?? ""
      state.name = payload.data.submissionMetaData.name ?? "";
      state.note = payload.data.submissionMetaData.note ?? "";
      state.imageRef = payload.data.imageRef ?? null;
      state.fileRef = payload.data.fileRef ?? null;
      state.pdfRef = payload.data.pdfRef ?? null;
      state.dateUpdated = payload.data.submissionMetaData.dateUpdated ?? null;
      state.dateSaved = payload.data.submissionMetaData.dateSubmitted ?? null;
      state.loadCoverLetterError = "";
      state.name = payload.data.submissionMetaData.name ?? "";
    },
    removeCoverLetter(state) {
      resetState(state, false);
    },
    logout(state) {
      resetState(state);
    },
    resetCoverLetter(state) {
      resetState(state);
    },
    errorLoadCurrentCoverLetter(state, payload) {
        log.logTrace("Setting error during load of current cover letter", "activeCoverLetter_store.errorLoadCurrentCoverLetter");
        resetState(state);
        state.loadCoverLetterError = payload;
    },
    isErrorCoverLetter(state, status, method) {
      if (status) {
        log.logTrace("Setting error in activeCoverLetter_store.errorLoadCurrentCoverLetter", method);
        resetState(state);
        state.isError = true;
      } else {
        state.isError = false;
      }
    },
    isSavingCoverLetter(state, status, method) {
      if (status) {
        log.logTrace("Setting isSaving in activeCoverLetter_store.isSavingCoverLetter", method);
        state.isSaving = true;
      } else {
        state.isSaving = false;
      }
    },
  },
  actions: {
    async newCoverLetter(context)
    { 
      context.commit("resetCoverLetter");
      context.commit("isSavingCoverLetter", false, "newCoverLetter");
    },
    async updateCoverLetter(context)
    {
      try {
        let payload = {
          resumeId: context.state.selectedResume?.id,
          id: context.state.coverLetterId,
          targetedIndustry: context.state.targetedIndustry,
          skills: context.state.selectedSkills,
          industryInterest: context.state.industryInterest,
          companyName: context.state.companyName,
          companyDescription: context.state.companyDescription,
          positionName: context.state.positionName,
          roleInterest: context.state.roleInterest,
          workingGoals: context.state.workingGoals,
          name: context.state.name,
          note: context.state.note,
          feel: context.state.coverLetterFeel,
          aiOptimizedPhrasing: context.state.aiOptimizedPhrasing,
        };
        context.commit("isSavingCoverLetter", true, "updateCoverLetter");
        let result = await axios.post('/api/SaveCoverLetter', payload);
        if (!result.data.success) {
          log.logWarning(
            "Failed to update Cover Letter data",
            "activeCoverLetter_store.updateCoverLetter"
          );
          context.commit("isErrorCoverLetter", true, "updateCoverLetter");
        } else {
          context.commit("isErrorCoverLetter", false, "updateCoverLetter");
        }
      } finally {
        context.commit("isSavingCoverLetter", false, "updateCoverLetter");
      }
    },
    async updateCoverLetterFile(context) {
      try {
        let payload = {
          id: context.state.coverLetterId,
          fileBase64: context.state.reportBase64,
          fileName: context.state.fileName,
          contentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",

        };
        context.commit("isSavingCoverLetter", true, "updateCoverLetterFile");
        let result = await axios.post('/api/SaveCoverLetterFile', payload);
        if (!result.data.success) {
          log.logWarning(
            "Failed to update Cover Letter file",
            "activeCoverLetter_store.updateCoverLetter"
          );
          context.commit("isErrorCoverLetter", true, "updateCoverLetterFile");
        } else {
          context.commit("isErrorCoverLetter", false, "updateCoverLetterFile");
        }
      } finally {
        context.commit("isSavingCoverLetter", false, "updateCoverLetterFile");
      }
    },
    async saveCoverLetter(context) {
      try {
        let payload = {
          resumeId: context.state.selectedResume?.id,
          id: context.state.coverLetterId,
          fileBase64: context.state.reportBase64,
          targetedIndustry: context.state.targetedIndustry,
          skills: context.state.selectedSkills,
          industryInterest: context.state.industryInterest,
          companyName: context.state.companyName,
          companyDescription: context.state.companyDescription,
          roleInterest: context.state.roleInterest,
          workingGoals: context.state.workingGoals,
          name: context.state.name,
          note: context.state.note,
          fileName: context.state.fileName,
          feel: context.state.coverLetterFeel,

          contentType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        };
        context.commit("isSavingCoverLetter", true, "saveCoverLetter");
        let result = await axios.post('/api/CreateCoverLetter', payload);
        context.commit("loadData", result.data);
        if (!result.data.success) {
          log.logWarning(
            "Failed to save Cover Letter data",
            "activeCoverLetter_store.saveCoverLetter"
          );
          context.commit("isErrorCoverLetter", true, "saveCoverLetter");
        } else {
          context.commit("isErrorCoverLetter", false, "saveCoverLetter");
          context.commit("loadData", result.data);
        }
      } finally {
        context.commit("isSavingCoverLetter", false, "saveCoverLetter");
      }
    },
    async loadCoverLetter(context, payload) {
      try {
        context.commit("isSavingCoverLetter", true, "loadCoverLetter");
        let result = await coverApi.GetCoverLetter(payload.reviewId);
        if (!result.success) {
          log.logWarning(
            "Failed to load User Cover Letter data",
            "CoverLetter_store.loadCoverLetter"
          );
          context.commit("isErrorCoverLetter", true, "loadCoverLetter");
        } else {
          log.logTrace(`Retrieved cover letter list for user.`, "coverLetter.loadCoverLetterList");
          context.commit("loadData", result);
          context.commit("isErrorCoverLetter", false, "loadCoverLetter");
        }
      } finally {
        context.commit("isSavingCoverLetter", false, "loadCoverLetter");
      }
    },
    async deleteCoverLetter(context, payload) {
      try {
        context.commit("isSavingCoverLetter", true, "deleteCoverLetter");
        let result = await coverApi.RemoveCoverLetter(payload.reviewId);
        if (!result.success) {
            log.logError(
                "Failed to remove user's saved resume",
                "currentReview_store.removeSavedResume"
            );
            context.commit("isErrorCoverLetter", true, "deleteCoverLetter");
        } else {
            log.logTrace(`Removed user resume review.`, "currentReview_store.removeSavedResume");
            context.commit("isErrorCoverLetter", false, "deleteCoverLetter");
        }
      } finally {
        context.commit("isSavingCoverLetter", false, "deleteCoverLetter");
      }
    },
  }
};

export { activeCoverLetterModule };