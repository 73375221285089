<template>
  <status-bar v-model="value">
    <h3 v-if="header.length > 0" class="pt-2">{{ header }}</h3>
    <h3
      v-if="subText.length > 0"
      class="text-body-1 font-italic text--secondary"
    >
      {{ subText }}
    </h3>
    <v-textarea
      :value="value"
      :label="label"
      :tabindex="tabindex"
      :autofocus="autofocus"
      :readonly="readonly"
      :disabled="disabled"
      :outlined="outlined"
      v-bind="$attrs"
      :rows="rows"
      :auto-grow="autogrow"
      v-on:input="updateValue($event)"
    ></v-textarea>
    <slot name="footer"></slot>
  </status-bar>
</template>

<script>
import StatusBar from "./StatusBar.vue";

// @ is an alias to /src
export default {
  name: "ResumationTextArea",
  components: {
    "status-bar": StatusBar
  },
  data: () => ({}),
  props: {
    value: {
      type: String
    },
    header: {
      type: String,
      default: ""
    },
    subText: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    tabindex: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    autogrow: {
      type: Boolean,
      default: false
    },
    rows: {
      type: [Number, String],
      default: 5
    }
  },
  methods: {
    updateValue: function(value) {
      this.$emit("input", value);
    }
  },
  mounted() {},
  computed: {}
};
</script>
