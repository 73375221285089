<template>
  <ul class="font-italic">
    <li v-for="(tip, i) in tips" :key="i">
      <span class="font-weight-bold">Tip #{{ i + 1 }}:</span>
      {{ tip }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "ResumationTipContainer",
  props: {
    tips: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
