import Vue from "vue";
import VueRouter from "vue-router";
import ResumeLanding from "../views/ResumeLanding.vue";
import Login from "../views/login/Login.vue";
import Home from "../views/Home.vue";
import * as env from "./../utility/env";
import store from "../store/index";

Vue.use(VueRouter);

const LOGIN = "Login";
const DEVLOGIN = "Developer Login";
const LOGOUT = "Log Out";
const TERMS = "Terms Of Use";
const PRIVACY = "Privacy Notice";
const COOKIE = "Cookie Notice";
const HOME = "Home";
const FORGOTPWD = "Forgot Password";
const FORGOTPWDACTION = "Forgot Password Action";
const CREATEACCOUNT = "Create Account";
const CONFIRMEMAILACTION = "Confirm Email Action";

const TITLEBASE = "Resumation";

const routes = [
  {
    path: "/",
    name: HOME,
    component: Home,
    meta: { hideNav: true, hideBar: true }
  },
  {
    path: "/Resume",
    name: "ResumeLanding",
    component: ResumeLanding,
    meta: { hideNav: true, hideBar: false }
  },
  {
    path: "/Welcome",
    name: "Welcome",
    component: () => import("../views/Introduction/Welcome.vue"),
    meta: {
      hideNav: false,
      hideBar: false,
      title: "Resumation Overview – Welcome to Resumation"
    }
  },
  {
    path: "/WhatMakesAGoodResume",
    name: "WhatMakesAGoodResume",
    component: () => import("../views/Introduction/WhatMakesAGoodResume.vue"),
    meta: {
      hideNav: false,
      hideBar: false,
      title: "Resumation Overview – What Makes a Good Resume?"
    }
  },
  {
    path: "/PreparationMaterials",
    name: "PreparationMaterials",
    component: () => import("../views/Introduction/PreparationMaterials.vue"),
    meta: {
      hideNav: false,
      hideBar: false,
      title: "Resumation Overview – Preparation Materials"
    }
  },
  {
    path: "/Summary",
    name: "Summary",
    component: () => import("../views/Introduction/Summary.vue"),
    meta: {
      hideNav: false,
      hideBar: false,
      title: "Resumation Overview - Summary"
    }
  },
  {
    path: "/login",
    name: LOGIN,
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Login" },
    component: Login
  },
  {
    path: "/dev/Login",
    name: DEVLOGIN,
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Login" },
    component: () => import("../views/login/DevLogin.vue")
  },
  {
    path: "/logout",
    name: LOGOUT,
    meta: { hideNav: true, hideBar: true },
    component: () => import("../views/login/Logout.vue")
  },
  {
    path: "/information",
    name: "Information",
    meta: { title: "General Information - Name & Contact" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Information.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    meta: { title: "General Information - Address" },
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/education",
    name: "Education",
    meta: { title: "Education Overview" },
    component: () => import("../views/Education.vue")
  },
  {
    path: "/adjectives",
    name: "Descriptors",
    meta: { title: "General Information – Descriptors" },
    component: () => import("../views/Adjectives.vue")
  },
  {
    path: "/career",
    name: "Your Interests",
    meta: { title: "General Information – Your Interests" },
    component: () => import("../views/CareerInterest.vue")
  },
  {
    path: "/hardskills",
    name: "Technical Skills",
    meta: { title: "General Information – Technical Skills" },
    component: () => import("../views/HardSkills.vue")
  },
  {
    path: "/softskills",
    name: "Leadership Skills",
    meta: { title: "General Information – Leadership Skills" },
    component: () => import("../views/SoftSkills.vue")
  },
  {
    path: "/languages",
    name: "Languages",
    meta: { title: "General Information – Languages" },
    component: () => import("../views/Languages.vue")
  },
  {
    path: "/organizations",
    name: "Organizations and Volunteering",
    meta: { title: "General Information – Organizations & Certifications" },
    component: () => import("../views/Organizations.vue")
  },
  {
    path: "/educationEntryGeneral/:key",
    name: "Education Entry",
    meta: { title: "Education - Basic Info" },
    component: () => import("../views/EducationEntryGeneral.vue")
  },
  {
    path: "/educationEntryDegree/:key",
    name: "Education Entry - Degree",
    meta: { title: "Education - Degree" },
    component: () => import("../views/EducationEntryDegree.vue")
  },
  {
    path: "/educationEntryAwards/:key",
    name: "Education Entry - Accolades and Activities",
    meta: { title: "Education - Accolades and Activities" },
    component: () => import("../views/EducationEntryAwards.vue")
  },
  {
    path: "/educationEntryAwardFocus/:key",
    name: "Education Entry - Award Focus",
    meta: { title: "Education - Award Focus" },
    component: () => import("../views/EducationEntryAwardFocus.vue")
  },
  {
    path: "/WorkCompanyGeneral/:key",
    name: "General Company Information",
    meta: { title: "Experience – Company Info" },
    component: () => import("../views/WorkCompanyGeneral.vue")
  },
  {
    path: "/WorkRoles/:key",
    name: "Work Roles",
    meta: { title: "Work - Roles" },
    component: () => import("../views/WorkRoles.vue")
  },
  {
    path: "/WorkResponsibilities/:key",
    name: "Work Roles",
    meta: { title: "Experience - Responsibilities" },
    component: () => import("../views/WorkResponsibilities.vue")
  },
  {
    path: "/WorkAccomplishments/:key",
    name: "Work Accomplishments",
    meta: { title: "Work - Accomplishments" },
    component: () => import("../views/WorkAccomplishments.vue")
  },
  {
    path: "/Work",
    name: "Work",
    meta: { title: "Work Overview" },
    component: () => import("../views/Work.vue")
  },
  {
    path: "/TargetIndustry",
    name: "Target Industry",
    meta: { title: "Target Industry" },
    component: () => import("../views/TargetIndustry.vue")
  },
  {
    path: "/TargetCompany",
    name: "Target Company",
    meta: { title: "Target Company" },
    component: () => import("../views/TargetCompany.vue")
  },
  {
    path: "/Strengths",
    name: "Strengths",
    meta: { title: "Strengths Overview" },
    component: () => import("../views/Strengths.vue")
  },
  {
    path: "/StrengthsCategory/:key",
    name: "Strengths Category",
    meta: { title: "Strengths - Category" },
    component: () => import("../views/StrengthsCategory.vue")
  },
  {
    path: "/FinalInfo",
    name: "FinalInfo",
    meta: { title: "Final Information" },
    component: () => import("../views/FinalInfo.vue")
  },
  {
    path: "/Review",
    name: "Review",
    meta: { title: "Resume" },
    component: () => import("../views/Review.vue")
  },
  {
    path: "/ReviewGeneralInfo",
    name: "General Information",
    meta: { title: "General Information" },
    component: () => import("../views/ReviewGeneralInfo.vue")
  },
  {
    path: "/Upgrade",
    name: "Upgrade",
    meta: { title: "Resumation Plus – Upgrade Today!" },
    component: () => import("../views/Upgrade.vue")
  },
  {
    path: "/Tester",
    name: "Tester",
    component: () => import("../views/Tester.vue")
  },
  {
    path: "/TermsOfUse",
    name: TERMS,
    component: () => import("../views/TermsAndConditions.vue"),
    meta: { hideNav: true, title: "Terms Of Use" }
  },
  {
    path: "/PrivacyNotice",
    name: PRIVACY,
    component: () => import("../views/PrivacyPolicy.vue"),
    meta: { hideNav: true, title: "Privacy Notice" }
  },
  {
    path: "/CookieNotice",
    name: COOKIE,
    component: () => import("../views/CookieNotice.vue"),
    meta: { hideNav: true, title: "Cookie Notice" }
  },
  {
    path: "/Options",
    name: "Options",
    component: () => import("../views/Options.vue"),
    meta: { title: "User Options" }
  },
  {
    path: "/SavedResumes",
    name: "Saved Resumes",
    component: () => import("../views/SavedResumes.vue"),
    meta: { title: "Saved Resumes" }
  },
  {
    path: "/ForgotPassword",
    name: FORGOTPWD,
    component: () => import("../views/Account/ForgotPassword.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Forgot Password" }
  },
  {
    path: "/CreateAccount",
    name: CREATEACCOUNT,
    component: () => import("../views/Account/CreateAccount.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Create Account" }
  },
  {
    path: "/ForgotPasswordAction",
    name: FORGOTPWDACTION,
    component: () => import("../views/Account/ForgotPasswordAction.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Forgot Password" }
  },
  {
    path: "/ConfirmEmailAction",
    name: CONFIRMEMAILACTION,
    component: () => import("../views/Account/ConfirmEmailAction.vue"),
    meta: { hideNav: true, hideBar: true, hideFooter: true, title: "Email Confirmed" }
  },
  {
    path: "/ResumeReview/:key",
    name: "Resume Review",
    component: () => import("../views/ResumeReview.vue"),
    meta: { title: "Resume Review" }
  },
  {
    path: "/CoverLetterTargetIndustry",
    name: "Cover Letter Target Industry",
    component: () => import("../views/CoverLetter/CoverLetterTargetIndustry.vue"),
    meta: {title: "Cover Letter Creator - Target Industry", beta: true}
  },
  {
    path: "/CoverLetterTargetCompany",
    name: "Cover Letter Target Company",
    component: () => import("../views/CoverLetter/CoverLetterTargetCompany.vue"),
    meta: {title: "Cover Letter Creator - Target Company", beta: true}
  },
  {
    path: "/CoverLetterReview",
    name: "Cover Letter Review",
    component: () => import("../views/CoverLetter/CoverLetterReview.vue"),
    meta: {title: "Cover Letter Creator - Customizations", beta: true}
  },
  {
    path: "/SavedCoverLetters",
    name: "Saved Cover Letters",
    component: () => import("../views/CoverLetter/SavedCoverLetters.vue"),
    meta: {title: "Cover Letter Creator - Saved Cover Letters", beta: true}
  },
  {
    path: "/CoverLetterView/:key",
    name: "Cover Letter View",
    component: () => import("../views/CoverLetter/CoverLetterView.vue"),
    meta: { title: "Cover Letter View" }
  },
  {
    path: "/JobFitAnalyzer",
    name: "Job Fit Analyzer",
    component: () => import("../views/Companion/JobFitAnalyzer.vue"),
    meta: {title: "Job Fit Analyzer", beta: true}
  },
  {
    path: "/CareerPathwayAnalyzer",
    name: "Career Pathway Analyzer",
    component: () => import("../views/Companion/CareerPathwayAnalyzer.vue"),
    meta: {title: "Career Pathway Analyzer", beta: true}
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, from, savedPosition) { //eslint-disable-line 
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  }
});

// login guard
router.beforeResolve((to, from, next) => {  //eslint-disable-line

  let indicatorCookie = document.cookie.split('; ').find(r => r.startsWith("rs_ind")) !== undefined;

  if (to.name === HOME) {
    next({ name: LOGIN }); // removing home page
  } else if (env.IsDevelopmentEnv() && to.name === DEVLOGIN) {
    next();
  } else if (to.name === LOGIN || to.name === LOGOUT || to.name === FORGOTPWD || to.name === CREATEACCOUNT || to.name === FORGOTPWDACTION || to.name === CONFIRMEMAILACTION) {
    next();
  } else if (to.name === TERMS || to.name === PRIVACY || to.name === COOKIE) {
    next();
  } else if (store.state.auth.isAuthed && indicatorCookie) {
    next();
  } else {
    next({ name: LOGIN });
  }
});

// change page title
// https://stackoverflow.com/questions/51639850/how-to-change-page-titles-when-using-vue-router
router.afterEach((to, from) => {    //eslint-disable-line 
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    if (to.meta.title) {
      document.title = `${TITLEBASE} - ${to.meta.title}`
    }
    else {
      document.title = TITLEBASE;
    }
  });
});

export default router;
