<template>
  <div class="fill-height">
    <v-container fluid class="container-main d-flex flex-column">
      <v-row justify="center" align="center" class="flex-grow-1 wide">
        <v-col cols="12" sm="10" md="7" lg="5" xl="5">
          <v-card flat v-if="loading">
            <h1>Getting Things Ready...</h1>
            <br />
            <v-progress-linear
              color="primary"
              :value="progress"
              height="15"
            ></v-progress-linear>
            <p class="text-body-1 text--secondary font-italic">
              {{ waitText }}
            </p>
          </v-card>
          <v-card flat v-if="error">
            <h1>Ouch! Something Went Wrong.</h1>
            <br />
            <v-progress-linear
              color="grey"
              :value="progress"
              height="15"
            ></v-progress-linear>
            <p class="text-body-1 pt-6">Refresh The Page - Let's Try Again.</p>
            <v-btn @click="refresh()" outlined>Refresh</v-btn>
          </v-card>
          <v-card flat v-if="ready">
            <h1 v-if="resumeFound">Resume: Ready</h1>
            <h1 v-else>Looks like we don't have a resume for you yet!</h1>
            <br />
            <v-progress-linear
              color="primary"
              :value="progress"
              height="15"
            ></v-progress-linear>
            <div v-if="resumeFound">
              <p class="text-body-1 pt-6">Let's review what you've got:</p>
              <v-btn
                @click="nav('/Information')"
                outlined
                autofocus
                tabindex="1"
                >Jump back in</v-btn
              >
            </div>
            <div v-else>
              <p class="text-body-1 pt-6">
                Looks like we don't have a resume for you.
              </p>
              <v-btn
                @click="nav('/Welcome')"
                outlined
                autofocus
                tabindex="1"
                >Get Started!</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <upgrade-banner v-if="!userIsPremium()" />
      <legal-prompt :showDialog="showTC"></legal-prompt>
    </v-container>
  </div>
</template>

<script>
import * as uuid from "../utility/guid";
import * as log from "../utility/logging/logger";
import LegalPrompt from "../components/LegalPrompt";
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import UpgradeBanner from "@/components/UpgradeBanner.vue";

// @ is an alias to /src
export default {
  name: "ResumeLanding",
  data: () => ({
    progress: 0,
    waitText: "",
    loading: true,
    error: false,
    ready: false,
    resumeFound: false,
    showTC: false,
  }),
  components: {
    "legal-prompt": LegalPrompt,
    "upgrade-banner": UpgradeBanner,
  },
  async mounted() {
    this.$store.dispatch("loadBaseLists");
    this.$store.dispatch("loadSettings");

    // terms
    this.waitText = "Checking for your user...";
    this.progress = 0;
    await this.fetchTerms();

    if (this.legalErrorInLoad) {
      this.setErrorState();
      return;
    }

    if (this.privacyRequired || this.termsRequired) {
      this.showTC = true;
    }

    if (!this.userIsPremium()) {
      await this.logUserIn();
    }

    this.progress = 33;
    this.waitText = "Looking for a saved resume...";
    await this.loadResumeList();

    if (this.resumeList.length > 0) {
      this.resumeId = this.resumeList[0];
      this.progress = 67;
      this.waitText = "Loading your resume...";
      await this.$store.dispatch("loadFullResume", this.resumeId);

      this.progress = 100;
      this.resumeFound = true;
      this.setReady();
    } else {
      this.resumeId = uuid.gen();
      this.progress = 100;
      this.resumeFound = false;
      this.setReady();
    }
  },
  methods: {
    ...mapActions([
      "loadFullResume",
      "loadBaseLists",
      "loadResumeList",
      "loadSettings",
    ]),
    ...mapActions("legal", ["fetchTerms"]),
    ...mapActions("auth", ["logUserIn"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    setErrorState() {
      this.loading = false;
      this.ready = false;
      this.error = true;
    },
    setReady() {
      this.loading = false;
      this.error = false;
      this.ready = true;
      this.$emit("updateShowNav", true);
    },
    refresh() {
      log.logWarning(
        "User triggered refresh of home page due to error.",
        "ResumeLanding.refresh"
      );

      window.location.href = "/Resume";
    },
    userIsPremium() {
      return this.isPremium;
    }
  },  
  computed: {
    ...mapFields(["resumeList", "resumeId"]),
    ...mapFields("legal", {
      legalDataLoaded: "dataLoaded",
      legalErrorInLoad: "errorInLoad",
    }),
    ...mapFields("auth", {
      isPremium: "isPremium",
    }),
    ...mapGetters("legal", ["privacyRequired", "termsRequired"]),
  },
};
</script>
