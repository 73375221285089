<template>
  <v-expansion-panel-header
    color="primary"
    class="white--text elevation-5 rounded-0"
  >
    <template v-slot:actions>
      <v-icon color="white"> $expand </v-icon>
    </template>
    <slot></slot>
  </v-expansion-panel-header>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ResumationExpansionPanelHeader",
  data: () => ({}),
  props: {},
  components: {},
  methods: {},
  mounted() {},
  computed: {}
};
</script>
