<template>
  <v-expansion-panel class="rounded-0 mb-2" :readonly="readonly">
    <slot></slot>
  </v-expansion-panel>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ResumationExpansionPanel",
  data: () => ({}),
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {}
};
</script>
