import { Get, Post, Delete } from "./ApiBase";


export async function CreateCoverLetter(payload) {
  return await Post('/api/CreateCoverLetter', payload);
}

export async function PostCoverLetter(payload) {
  return await Post("/api/SetCoverLetter", payload);
}

export async function GetCoverLettersList() {
  return await Get("/api/GetCoverLetterList")
}

export async function GetCoverLetter(reviewId) {
  return await Get(`/api/GetCoverLetter?reviewId=${reviewId}`);
}

export async function RemoveCoverLetter(reviewId) {
  return await Delete(`/api/RemoveSavedCoverLetter?savedCoverLetterId=${reviewId}`);
}

export async function PostUpdateCoverLetter(payload) {
  return await Post("/api/SaveCoverLetter", payload);
}

export async function PostUpdateCoverLetterFile(payload) {
  return await Post("/api/SaveCoverLetterFile", payload);
}

