var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-dialog',{attrs:{"value":_vm.value,"title":"Cookie Manager"},on:{"input":function($event){return _vm.updateValue($event)}}},[_c('v-card-text',{staticClass:"text--primary text-body-1 pt-4 pb-3"},[_c('p',{staticClass:"text-body-2"},[_vm._v(" When you visit our website, we store information on your browser - typically in the form of cookies. You can choose to not allow some types of cookies. Use the options below to allow or block the different categories of cookies that we collect. ")]),_c('p',{staticClass:"text-body-2"},[_vm._v(" For more information, you can review our "),_c('a',{attrs:{"href":"/CookieNotice","target":"_blank"}},[_vm._v("Cookie Notice")]),_vm._v(". ")])]),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('span',{staticClass:"text-body-1 text--primary"},[_vm._v("Essential Cookies")]),_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pb-1 pl-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" These cookies are strictly necessary to provide you with services available through our Websites. You cannot disable these cookies. ")])])],1),_c('div',[_c('v-switch',{attrs:{"disabled":"","color":"primary"},model:{value:(_vm.essentialCookies),callback:function ($$v) {_vm.essentialCookies=$$v},expression:"essentialCookies"}})],1)]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('span',{staticClass:"text-body-1 text--primary"},[_vm._v(" Performance and Functionality Cookies ")]),_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pb-1 pl-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" These cookies are used to enhance the performance and functionality of our Websites but are non-essential to their use. ")])])],1),_c('div',[_c('v-switch',{attrs:{"color":"primary"},model:{value:(_vm.perfCookies),callback:function ($$v) {_vm.perfCookies=$$v},expression:"perfCookies"}})],1)]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('span',{staticClass:"text-body-1 text--primary"},[_vm._v(" Analytics and Customization Cookies ")]),_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pb-1 pl-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" These cookies collect information that can be used to do things like help us understand how our Websites are being used, or troubleshoot issues. ")])])],1),_c('div',[_c('v-switch',{attrs:{"color":"primary"},model:{value:(_vm.analyticsCookies),callback:function ($$v) {_vm.analyticsCookies=$$v},expression:"analyticsCookies"}})],1)]),_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('div',[_c('span',{staticClass:"text-body-1 text--primary"},[_vm._v("Advertising Cookies")]),_c('v-tooltip',{attrs:{"top":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"pb-1 pl-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('span',[_vm._v(" These cookies are used to make advertising messages more relevant to you. ")])])],1),_c('div',[_c('v-switch',{attrs:{"color":"primary"},model:{value:(_vm.advertCookies),callback:function ($$v) {_vm.advertCookies=$$v},expression:"advertCookies"}})],1)])]),_c('v-card-actions',[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveSettings()}}},[_vm._v(" Save Choices ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.acceptAll()}}},[_vm._v(" Accept All ")])],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-btn',{attrs:{"color":"gray","text":""},on:{"click":function($event){return _vm.updateValue(false)}}},[_vm._v(" Cancel ")])],1)],1),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }