<template>
  <v-container fluid class="login-bkg height-100">
    <v-overlay v-model="loading">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-overlay>
    <v-row :class="$vuetify.breakpoint.xs ? 'pt-75' : 'pt-150'">
      <v-spacer></v-spacer>
      <v-col cols="12" sm="10" md="7" lg="5" xl="4">
        <v-card tile>
          <v-system-bar
            height="60"
            color="primary"
            class="white--text elevation-5"
          >
            <v-card-title>
              <h3>Resumation Login</h3>
            </v-card-title>
          </v-system-bar>
          <v-system-bar height="5" color="accent" class=""></v-system-bar>
          <v-card-text>
            <span class="text-body-1 text--primary"
              >To continue, log in to Resumation:</span
            >
          </v-card-text>
          <v-divider />
          <v-card-text>
            <div class="d-flex justify-center">
              <p>Use Email and Password</p>
            </div>
            <div class="px-5">
              <v-form ref="form" v-model="valid" @submit.prevent="logIn()">
                <v-text-field
                  persistent-placeholder
                  v-model="email"
                  label="Email Address"
                  :rules="emailRules"
                  type="text"
                  dense
                  class="pb-1"
                ></v-text-field>
                <v-text-field
                  persistent-placeholder
                  v-model="password"
                  label="Password"
                  dense
                  class="pb-1"
                  :rules="pwdRules"
                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd ? 'text' : 'password'"
                  @click:append="showPwd = !showPwd"
                ></v-text-field>
                <div class="d-flex justify-center" v-if="loginErrorText !== ''">
                  <p class="error--text text-body-1 font-weight-medium">
                    {{ loginErrorText }}
                  </p>
                </div>
                <div class="d-flex justify-space-between align-center">
                  <div>
                    <div class="pb-2">
                      <a
                        @click="navTo('/ForgotPassword')"
                        class="text-body-1 text-decoration-underline"
                        >Forgot your password?</a
                      >
                    </div>
                    <div class="pb-2">
                      <a
                        @click="navTo('/CreateAccount')"
                        class="text-body-1 text-decoration-underline"
                        >Create an Account</a
                      >
                    </div>
                  </div>
                  <div>
                    <v-btn
                      type="submit"
                      :disabled="loading"
                      color="primary"
                      class="px-8"
                      >Log In</v-btn
                    >
                  </div>
                </div>
              </v-form>
            </div>
          </v-card-text>
          <div class="d-flex justify-center align-center pt-3">
            <v-divider />
            <div>
              <p class="mb-0 px-5 text--secondary text-body-2">Or</p>
            </div>
            <v-divider />
          </div>
          <v-card-text class="pt-0">
            <v-list>
              <v-list-item-group v-model="selectedItem">
                <v-list-item
                  v-for="(item, i) in items"
                  :key="i"
                  @click="hardNavTo(item.href)"
                  :disabled="item.disabled"
                >
                  <v-list-item-icon>
                    <v-img
                      aspect-ratio="1"
                      width="30"
                      max-width="30"
                      :src="require(`@/assets/${item.filename}`)"
                      contain
                    ></v-img>
                  </v-list-item-icon>
                  <v-list-item-content class="text-body-1">{{
                    item.text
                  }}</v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { RefreshToken } from "../../api/AuthApi";
import * as login from "../../api/LoginApi";

// @ is an alias to /src
export default {
  name: "Login",
  data: () => ({
    loading: true,
    selectedItem: -1,
    email: "",
    password: "",
    showPwd: false,
    valid: true,
    loginErrorText: "",
    emailRules: [(value) => !!value || "Required."],
    pwdRules: [(v) => !!v || "Required."],
    items: [
      {
        text: "Continue with LinkedIn",
        filename: "LI-In-Bug.png",
        href: "/api/login/linkedin",
        disabled: false,
      },
      {
        text: "Continue with a Microsoft Account",
        filename: "ms-symbollockup_mssymbol_19.svg",
        href: "/api/login/microsoft",
        disabled: false,
      },
      {
        text: "Continue with a Google Account",
        filename: "btn_google_light_normal_ios.svg",
        href: "/api/login/google",
        disabled: false,
      },
    ],
  }),
  async mounted() {
    await this.checkLoggedIn();
  },
  methods: {
    async checkLoggedIn() {
      try {
        let isLoggedIn = await this.logUserIn();
        if (isLoggedIn) {
          await RefreshToken();
          this.loading = false;
          this.navTo("/Resume");
        }
      } finally {
        this.loading = false;
      }
    },
    ...mapActions("auth", ["logUserIn"]),
    hardNavTo(href) {
      window.location.href = href;
    },
    navTo(href) {
      this.$router.push({ path: href });
    },
    async logIn() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      try {
        this.loginErrorText = "";
        this.loading = true;

        const loginResponse = await login.LoginManaged(
          this.email,
          this.password
        );
        if (!loginResponse.success) {
          this.loginErrorText = loginResponse.displayText;
        } else {
          this.checkLoggedIn();
        }
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.pt-150 {
  padding-top: 150px;
}

.pt-75 {
  padding-top: 75px;
}
</style>
