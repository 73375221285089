<template>
  <div class="sm-4">
    <v-btn
      block
      outlined
      @click="dialog = true"
      class="mt-2 mt-sm-4"
      :style="{
        backgroundColor: accentButton ? '#FFF1CA' : ''
      }"
    >
      <v-icon left>mdi-help-circle-outline</v-icon>
      {{ ctaText }}
    </v-btn>
    <r-dialog v-model="dialog" :title="cardTitle" :maxWidth="maxWidth">
      <v-card-text class="text--primary text-body-1 pt-6 pb-0">
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="dialog = false">
          Close
        </v-btn>
      </v-card-actions>
    </r-dialog>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    cardTitle: {
      type: String,
      default: ""
    },
    ctaText: {
      type: String,
      default: ""
    },
    accentButton: {
      type: Boolean,
      default: false
    },
    maxWidth: {
      type: Number
    }
  },
  data: () => ({
    dialog: false
  })
};
</script>
