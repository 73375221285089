<template>
  <v-container
    @click="nav('/Upgrade')"
    outlined
    class="mb-2 text-center upgrade-banner"
  >
    <h2>
      Do you want to maximize your experience with Resumation? Click here to
      Upgrade to Resumation Plus!
    </h2>
    <p class="my-6 text-body-1">
      Resumation Plus offers an AI optimization feature for resume content, the
      the ability to save and store multiple resumes, and much more for only $5
      a month!
    </p>
    <h2>Click here to find out more!</h2>
  </v-container>
</template>

<script>
export default {
  name: "UpgradeBanner",
  data: () => ({}),
  methods: {
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    }
  }
};
</script>

<style scoped>
.upgrade-banner {
  border: 4px solid var(--v-error-lighten1);
}

.upgrade-banner:hover {
  cursor: pointer;
}

.upgrade-banner h2 {
  line-height: 1.1;
}
</style>
