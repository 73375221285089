<template>
    <status-bar v-model="anyValues">
      <h3 v-if="titleSet" class="pb-3 pt-2">{{ titleText }}</h3>
      <v-autocomplete
        :return-object=true
        :value="value"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :label="label"
        :tabindex="tabindex"
        :autofocus="autofocus"
        :readonly="readonly"
        :disabled="disabled"
        @change="updateValue"
        v-bind="$attrs"
      ></v-autocomplete>
    </status-bar>
  </template>
  
  <script>
  import StatusBar from "./StatusBar.vue";
  import * as disp from "@/utility/display.js";
  
  // @ is an alias to /src
  export default {
    name: "ResumationSingleSelectAutoComplete",
    components: {
      "status-bar": StatusBar
    },
    data: () => ({}),
    props: {
      value: {
        type: [ Object]
      },
      items: {
        type: Array
      },
      label: {
        type: String,
        default: ""
      },
      titleText: {
        type: String,
        default: ""
      },
      tabindex: {
        type: String,
        default: ""
      },
      autofocus: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      itemText: {
        type: String,
        default: "name"
      },
      itemValue: {
        type: String,
        default: "id"
      },
    },
    methods: {
      updateValue: function(value) {
        this.$emit("input", value);
      }
    },
    mounted() {},
    computed: {
      anyValues() {
        if (typeof this.value === "object" && this.value.id ) {
        return "V";
      }
  
        return "";
      },
      titleSet() {
        return !disp.IsNullorWhitespace(this.titleText);
      }
    }
  };
  </script>
  